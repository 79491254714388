import React from 'react';
import logo from './assets/images/autocar-logo.svg';
import './App.css';
import ProjectList from './components/ProjectList';

function App() {
  return (
    <section className="w-full bg-slate-50 h-screen">
        <div className='pt-10 pb-5 flex flex-col items-center'>
          <img src={logo} alt="logo" />
        </div>
        <div className='flex flex-col items-center'>
          <ProjectList/>
        </div>
    </section>
  );
}

export default App;
