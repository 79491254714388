import React from "react";
import ATSBannerImg from "../assets/images/attendance_tracker_system.png";
import OrderBannerImg from "../assets/images/order_center.png";
import LmsBannerImg from "../assets/images/lms.png";

const ProjectList = () => {

    function redirectToUrl(url: string) {
        window.open(url, '_blank');
    }

    return(
      <section className="w-full max-w-[1200px] py-10 px-10 flex flex-row flex-wrap">
        <div className="w-4/12 p-3">
            <div onClick={() => redirectToUrl('https://studentracker.aifirstdemo.com/')} className="w-full cursor-pointer bg-white transition duration-150 ease-in hover:shadow-xl rounded-xl ring-2 ring-slate-100 p-3">
                <div className="rounded-xl overflow-hidden h-[200px] ring-1 ring-slate-200">
                    <img className="object-cover object-center w-full h-full" src={ATSBannerImg} alt="Attendance Tracker System" />
                </div>
                <div className="py-3 px-2">
                    <h2 className="font-bold text-lg">Attendance Tracker System</h2>
                    <p className="text-gray-600">Attendance Tracking System digitizes monitoring, replacing paper methods, streamlining processes, and ensuring accuracy.</p>
                </div>
            </div>
        </div>
        <div className="w-4/12 p-3">
            <div onClick={() => redirectToUrl('https://ordercenter.aifirstdemo.com/')} className="w-full cursor-pointer bg-white transition duration-150 ease-in hover:shadow-xl rounded-xl ring-2 ring-slate-100 p-3">
                <div className="rounded-xl overflow-hidden h-[200px] ring-1 ring-slate-200">
                    <img className="object-cover object-left w-full h-full" src={OrderBannerImg} alt="Awwwex Order Center" />
                </div>
                <div className="py-3 px-2">
                    <h2 className="font-bold text-lg">Awwwex Order Center</h2>
                    <p className="text-gray-600">The Order Center is a centralized hub managing all incoming and outgoing orders, smooth operations, order fulfillment, and customer satisfaction.</p>
                </div>
            </div>
        </div>
        <div className="w-4/12 p-3">
            <div onClick={() => redirectToUrl('https://lms.aifirstdemo.com/')} className="w-full cursor-pointer bg-white transition duration-150 ease-in hover:shadow-xl rounded-xl ring-2 ring-slate-100 p-3">
                <div className="rounded-xl overflow-hidden h-[200px] ring-1 ring-slate-200">
                    <img className="object-cover object-center w-full h-full" src={LmsBannerImg} alt="LMS" />
                </div>
                <div className="py-3 px-2">
                    <h2 className="font-bold text-lg">LMS</h2>
                    <p className="text-gray-600">LMS revolutionizes education with digital platforms for organizing, delivering, and tracking learning. Features include course management, content creation.</p>
                </div>
            </div>
        </div>
      </section>
    )
};

export default ProjectList;
